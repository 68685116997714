.blog-page-layout-v1 {
	padding-top: var(--page-margin-top);
}

.blog-list-index {
	.blog-card-v1 {
		display: inline-block;

		.blog-body {
			width: 100%;

			.lead-bl {
				min-height: 90px;
			}
		}
	}
}