.static-v1 {
	margin-top: var(--page-margin-top);

	.gallery-heading {
		padding: 0 7px;
	}

	.Bildergalerie-boxes {
		margin: 0 -7px;
	}

	.ostee-cont-bl {
		margin-bottom: 30px;

		.img-bl {
			margin-bottom: 15px;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	.inner-banner {
		&.ostee {
			background-image: url(RESOURCE/img/inner_banner_ostseebad.jpg);
		}

		&.osteenew {
			background-image: url(RESOURCE/img/inner_banner_ostseebad_new.jpg);
		}

		&.einostsee {
			background-image: url(RESOURCE/img/banner-einostsee.jpg);
		}

		&.freizeit {
			background-image: url(RESOURCE/img/banner-freizeit_inner.jpg);
		}

		&.gastronomie {
			background-image: url(RESOURCE/img/banner-gastronomie_inner.jpg);
		}

		&.monchguter {
			background-image: url(RESOURCE/img/banner-monchguter.jpg);

			@media (min-width: 1200px) {
				background-position: 50% 60%;
			}
		}
	}

	.Bildergalerie-boxes {

		.row {
			margin-left: -7px;
			margin-right: -7px;
			display: flex;
			flex-wrap: wrap;

			@media (max-width: 767px) {
				margin: 0;
			}

			.gallery-col {
				width: 33.33%;
				padding: 0 7px;
				display: inline-block;
				margin-bottom: 14px;

				@media (max-width: 767px) {
					width: 100%;
					padding: 0;
					margin-bottom: 15px !important;
				}

				.imagegallery {
					img {
						max-width: 100%;
						width: 100%;
					}
				}
			}
		}

		.Bildergalerie-box {
			padding-left: 10px;
			padding-right: 10px;

			img {
				width: 100%;
				margin-bottom: 30px;
			}
		}
	}

	.list-view-card {
		margin-bottom: 20px;
		width: 100%;
		box-shadow: 0 0.1rem 1rem rgb(0 0 0 / .1);
		background-color: var(--color-white);
		padding: 10px;
		display: flex;

		@media (max-width: 767px) {
			flex-direction: column;
		}

		&:hover {
			box-shadow: 0 4px 25px 0 rgb(0 0 0 / 50%);
			-webkit-transform: translateX(1px);
			transform: translateX(1px);
		}

		.image {
			height: 200px;
			overflow: hidden;
			position: relative;
			width: 300px;

			@media (max-width: 767px) {
				width: 100%;
			}


			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.list-body {
			padding-top: 10px;
			text-align: left;
			padding-left: 15px;
			width: calc(100% - 350px);
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			@media (max-width: 767px) {
				width: 100%;
			}

			.title {
				font-size: var(--blog-card-title-font-size);
				color: var(--blog-card-title-font-color);
				/*min-height: 60px;*/
				font-weight: 600;
				font-family: var(--font-family-main);
				margin-bottom: 1rem !important;
			}
		}

	}

}