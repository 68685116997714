.footer-v2 {
	.main-footer {
		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);

		a {
			color: var(--footer-font-color);
		}

		.bottom {
			height: 40px;
		}
	}
}