.blog-card-v1 {
	margin-bottom: 20px;
	width: 100%;
	box-shadow: 0 0.1rem 1rem rgb(0 0 0 / .1);
	background-color: var(--color-white);
	padding: 10px;
	display: flex;

	@media (max-width: 767px) {
		flex-direction: column;
	}

	&:hover {
		box-shadow: 0 4px 25px 0 rgb(0 0 0 / 50%);
		-webkit-transform: translateX(1px);
		transform: translateX(1px);
	}

	.image {
		height: 200px;
		overflow: hidden;
		position: relative;



		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.blog-body {
		padding-top: 10px;
		text-align: left;
		padding-left: 15px;
		width: calc(100% - 350px);
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@media (max-width: 767px) {
			width: 100%;
		}
	}

	.date {
		position: absolute;
		font-size: 0.85rem;
		background-color: var(--color-primary);
		padding: 2px 5px;
		color: var(--color-white);
		bottom: 10px;
		right: 10px;

	}

	.title {
		font-size: var(--blog-card-title-font-size);
		color: var(--blog-card-title-font-color);
		/*min-height: 60px;*/
		font-weight: 600;
	}

	.link {
		text-align: left;
	}
}